<template>
  <div class="dashboard-container">
    <!-- grafica -->
    <div>
      <el-row>
        <el-col :lg="4" :md="4" :sm="8" :xs="8">
          <el-statistic
            group-separator="'"
            :precision="0"
            :value="components.activeProjects"
            title="Proyectos Activos"
          >
            <template slot="prefix">
              <i class="el-icon-data-line" style="color: rgb(60, 254, 60)"></i>
            </template>
          </el-statistic>
        </el-col>
        <el-col :lg="4" :md="4" :sm="8" :xs="8">
          <el-statistic
            group-separator="'"
            :precision="0"
            :value="components.completedProjects"
            title="Proyectos Inactivos"
          >
            <template slot="prefix">
              <i class="el-icon-collection" style="color: rgb(54, 55, 54)"></i>
            </template>
          </el-statistic>
        </el-col>
        <el-col :lg="4" :md="4" :sm="8" :xs="8">
          <el-statistic
            group-separator=" "
            :precision="0"
            :value="components.totalDonated"
            title="Total Donado"
          >
            <template slot="prefix">
              <i class="el-icon-coin" style="color: rgb(60, 254, 60)"></i>
            </template>
          </el-statistic>
        </el-col>
        <el-col :lg="4" :md="4" :sm="8" :xs="8">
          <el-statistic
            group-separator=" "
            :precision="0"
            :value="components.countActiveSubs"
            title="Suscripciones Activas"
          >
            <template slot="prefix">
              <i class="el-icon-bank-card" style="color: rgb(60, 254, 60)"></i>
            </template>
        </el-statistic>
        </el-col>
        <el-col :lg="4" :md="4" :sm="8" :xs="8">
          <el-statistic
            group-separator=" "
            :precision="0"
            :value="components.totalUsers"
            title="Usuarios Registrados"
          >
            <template slot="prefix">
              <i class="el-icon-user" style="color: rgb(60, 254, 60)"></i>
            </template>
        </el-statistic>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <el-date-picker
        v-model="filterDateRegisterRangeLineChart"
        v-on:change="handleFilterDateRegisterLineChart()"
        size="mini"
        type="daterange"
        align="right"
        unlink-panels
        format="dd-MM-yyyy"
        range-separator="a"
        start-placeholder="Fecha inicial"
        end-placeholder="Fecha final"
        :picker-options="pickerOptions">
      </el-date-picker>
      <LineChart :schemaList="userData" :schemaLabel="userLabel" ></LineChart>
      <el-divider></el-divider>
      <ColumnChart :schemaList="projectData"></ColumnChart>
    </div>
    <div v-if="register == false">
      <el-row :gutter="12">
        <el-col :span="24">
          <el-card shadow="always" class="margin-button-20">
            <highcharts :options="chartOptions"></highcharts>
          </el-card>
        </el-col>
      </el-row>
    </div>
    <!-- end grafica -->
  </div>
</template>

<script>

import LineChart from '@/components/Highcharts/LineChart.vue'
import ColumnChart from '@/components/Highcharts/ColumnChart.vue'
import moment from 'moment'
import { getData, getDataUser, getDataProject } from '@/api/dashboard'

export default {
  components: {
    LineChart,
    ColumnChart
  },
  mounted () {
    this.searchFormLineChart.initDate = moment(this.filterDateRegisterRangeLineChart[0])
    this.searchFormLineChart.endDate = moment(this.filterDateRegisterRangeLineChart[1])
    this.getDataDashBoard()
    this.getDataUser()
    this.getDataProject()
  },
  data () {
    return {
      components: {
        activeProjects: 0,
        completedProjects: 0,
        countActiveSubs: 0,
        totalDonated: 0,
        totalUsers: 0
      },
      register: true,
      chartOptions: {
        chart: {
          type: 'line'
        },
        title: {
          text: 'Monitoreo'
        },
        // subtitle: {
        //   text: 'Source: WorldClimate.com'
        // },
        xAxis: {
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        },
        yAxis: {
          title: {
            text: 'Temperature (°C)'
          }
        },
        plotOptions: {
          line: {
            dataLabels: {
              enabled: true
            },
            enableMouseTracking: false
          }
        },
        series: [{
          name: 'Tokyo',
          data: [7.0, 6.9, 9.5, 14.5, 18.4, 21.5, 25.2, 26.5, 23.3, 18.3, 13.9, 9.6]
        }, {
          name: 'London',
          data: [3.9, 4.2, 5.7, 8.5, 11.9, 15.2, 17.0, 16.6, 14.2, 10.3, 6.6, 4.8]
        }]
      },
      userData: [],
      userLabel: [],
      projectData: [],
      filterDateRegisterRangeLineChart: [moment().subtract(7, 'days').toDate(), moment().toDate()],
      pickerOptions: {
        shortcuts: [{
          text: 'Semana pasada',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: 'Mes pasado',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '3 Meses atrás',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '6 Meses atrás',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 180)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '9 Meses atrás',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 270)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '1 Año atrás',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      searchFormLineChart: {
        initDate: '',
        endDate: ''
      }
    }
  },
  created () {
  },
  methods: {
    async getDataDashBoard () {
      getData()
        .then((response) => {
          this.components = response.data
        })
    },
    async getDataUser () {
      getDataUser(this.searchFormLineChart)
        .then((response) => {
          if (response.data) {
            this.userData = []
            this.userLabel = []
            var data = response.data
            var line = {
              name: 'Usuarios',
              data: []
            }
            Object.keys(data).forEach(key => {
              line.data.push(data[key])
              this.userLabel.push(key)
            })
            this.userData.push(line)
          }
        })
    },
    async getDataProject () {
      getDataProject()
        .then((response) => {
          if (response.data) {
            this.projectData = []
            var data = response.data
            var serie = {
              name: 'Proyectos',
              colorByPoint: true,
              data: []
            }
            Object.keys(data).forEach(key => {
              serie.data.push({ name: data[key].name, y: data[key].total, drilldown: data[key].name })
            })
            this.projectData.push(serie)
          }
        })
    },
    handleFilterDateRegisterLineChart () {
      if (this.filterDateRegisterRangeLineChart) {
        this.searchFormLineChart.initDate = moment(this.filterDateRegisterRangeLineChart[0])
        this.searchFormLineChart.endDate = moment(this.filterDateRegisterRangeLineChart[1])
        this.getDataUser()
      } else {
        this.searchFormLineChart.initDate = ''
        this.searchFormLineChart.endDate = ''
        this.getDataUser()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.dashboard {
  &-container {
    margin: 30px;
  }
  &-text {
    font-size: 30px;
    line-height: 46px;
  }
}
.el-carousel__item h3 {
    color: #475669;
    font-size: 40px;
    opacity: 0.75;
    line-height: 300px;
    margin: 0;
    text-align: center;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  }
</style>
