import request from '@/utils/request'
export function counter () {
  return request({
    url: '/api-manager/count',
    method: 'get'
  })
}
export function fileItemNotSync (data) {
  return request({
    url: '/api-manager/file-item-not-sync',
    method: 'post',
    data
  })
}
export function fileItemSync (data) {
  return request({
    url: '/api-manager/file-item-sync',
    method: 'post',
    data
  })
}
export function userFileItem () {
  return request({
    url: '/api-manager/user-file-item',
    method: 'post'
  })
}
export function userRegister (data) {
  return request({
    url: '/api-manager/user-register',
    method: 'post',
    data
  })
}
export function userRegisterCountry (data) {
  return request({
    url: '/api-manager/user-register-country',
    method: 'post',
    data
  })
}
export function userMoreAccess () {
  return request({
    url: '/api-manager/user-more-access',
    method: 'post'
  })
}
export function userDateAccess (data) {
  return request({
    url: '/api-manager/user-date-access',
    method: 'post',
    data
  })
}
export function getData () {
  return request({
    url: '/api-manager/dashboard',
    method: 'get'
  })
}

export function getDataUser (data) {
  return request({
    url: '/api-manager/dashboard/user',
    method: 'post',
    data
  })
}

export function getDataProject (data) {
  return request({
    url: '/api-manager/dashboard/project',
    method: 'post',
    data
  })
}
